<template>
  <offer-details-modal
    v-if="showOfferDetailsModal"
    isSelfFunded
    :close="() => (showOfferDetailsModal = false)"
  />
  <primary-button
    @click="showOfferDetailsModal = true"
    role="createSelfFundedOfferButton"
    :disabled="disabled"
    :class="{ 'border border-gray-300': disabled }"
  >
    {{ $t("DEALS.DEAL_PROGRESS.CREATE_OFFER") }}
  </primary-button>
</template>

<script setup lang="ts">
import { ref } from "vue";
import OfferDetailsModal from "../modals/OfferDetailsModal.vue";
defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});

const showOfferDetailsModal = ref(false);
</script>
